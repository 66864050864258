// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

interface LinkObject {
    title: string;
    url: string;
  }

export const configJSON = require("./config");

export interface Item {
    data: any;
    id: string;
    type: string;
    attributes: {
        id: string;
        order_status: string;
        total_price: number;
        created_at: string;
        placed_date_time: string;
        updated_at: string;
        reservation_service: {
            id: string;
            price: number;
            created_at: string;
            updated_at: string;
            city: string;
            full_address: string;
            reservation_date: string;
            state: string;
            zip_code: number;
            service_name: string;
            booking_status: string;
            slot_start_time: string;
            slot_end_time: string;
        };
        reservation_service_image: string;
    };
}


export interface Props {
    navigation: any;
    id: string;
}

interface SProps {
    faqList: any,
    faqLoader: boolean,
    socialLinksData: {icons: string, navigationUrl: string}[];
    poBOLinksData: LinkObject[];
}

interface SSProps {
    ssId: string;
}

export default class FaqsController extends BlockComponent<
    Props,
    SProps,
    SSProps
> {
    uniqueSessionRequesterId: string = "";
    authToken: string = "";
    getFaqsApiCallId: string = "";
    sortTypes: string[] = ["Ascending Order", "Descending Order"];
    unsubscribefocuse?: () => void;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            faqList: [],
            faqLoader: true,
            socialLinksData: [],
            poBOLinksData: []

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );

            if (requesterId === this.uniqueSessionRequesterId) {
                const sessionToken = message.getData(
                    getName(MessageEnum.SessionResponseToken)
                );
                this.authToken = sessionToken;

            }
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getFaqsApiCallId) {
                
                this.setState({ faqList: responseJson?.faqs?.data }, () => {
                    
                    this.setState({ faqLoader: false })
                });
            }
        }

    }

    requestSessionData() {
        const sessionMsg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.uniqueSessionRequesterId = sessionMsg.messageId;
        this.send(sessionMsg);
        return true;
    }

    async componentDidMount() {
        super.componentDidMount();
        this.fetchFAQs()
        const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
        this.setState({socialLinksData: mySocialLinksData });
        const allLinksData = await getStorageData("BOandPOLinks",true)
        this.setState({poBOLinksData: allLinksData}) 
    }



    fetchFAQs = () => {

        const getReservationHistoryListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFaqsApiCallId =
            getReservationHistoryListMsg.messageId;

        getReservationHistoryListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_content_management/faqs`
        );


        getReservationHistoryListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `GET`
        );

        runEngine.sendMessage(
            getReservationHistoryListMsg.id,
            getReservationHistoryListMsg
        );

        return true;
    };






























}

// Customizable Area End
