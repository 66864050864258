// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  Divider,
  Menu,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  ClickAwayListener,
  Avatar
} from "@material-ui/core";

import { styled, ThemeProvider } from "@material-ui/core/styles";




import ReservationsController, {
  Props,
  configJSON,
  Item,
} from "./ReservationsController";
import { boxShadows, theme } from "../../../components/src/theme";
import Navbar from "../../../components/src/Navbar";
import Footer from "../../../components/src/Footer.web";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { Dog1 } from "../../landingpage/src/assets";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CustomDate from "../../../components/src/CustomizeCalender";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import { plusIcon, threeDots } from "../../shoppingcart/src/assets";
import Loader from "../../../components/src/Loader.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";





export default class Reservations extends ReservationsController {
  constructor(props: Props) {
    super(props);
  }

  renderPetInformation = () => {
    
    return (
      <>
        <ClickAwayListener 
        onClickAway={this.closePet}
        
        
        >
          <Box style={webStyle.modalPopup as React.CSSProperties}>
            <Box style={webStyle.modalPopupContent}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                     <Grid container direction="row" spacing={2}>
                      
                      {this.state.PetList.map((petInfo: any) => {
                      
                        return (
                          <Grid item xs={12} style={webStyle.petListBox} key={petInfo?.id}>
                            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                              <Grid 
                               xs={1}  item  >
                                <Checkbox
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                  data-test-id='checkBoxSelectServices'
                                  style={{ padding: "0px" }}
                                  color="default"
                                />
                              </Grid>
                              <Grid item 
                              xs={9}
                              >
                                <Grid  direction="row" spacing={2} alignItems="center"  container>
                                  <Grid item>

                                    <Avatar
                                      src={petInfo?.attributes?.pet_photo}
                                    />
                                  </Grid>
                                  <Grid 
                                  item
                                  >

                                    <Typography 
                                    variant="subtitle1" 
                                    style={webStyle.headingText}>
                                      {petInfo?.attributes?.name}
                                    </Typography></Grid>
                                  <Grid item>
                                    <Typography style={webStyle.headingBlack} variant="subtitle2" >
                                      ({petInfo?.attributes?.pet_type})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}
                                style={{ position: 'relative' }}
                                container justifyContent="flex-end" alignItems="center">
                                <img src={threeDots}
                                  data-test-id="open_edit_pet"
                                
                                />
                               
                               </Grid>
                            </Grid>
                          </Grid>
                        )

                      })}

                    </Grid>  

                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">

                      <Grid item>
                        <Typography variant="body1" color="error" style={webStyle.petInfoText}>
                          You have to add 2 dogs and 1 cat detail as per your order cart
                        </Typography>
                      </Grid >
                      <Grid item>
                        <img src={plusIcon} width={16} height={16} />
                        <Typography display="inline" align="center" variant="body1" color="secondary"
                          data-test-id="add_pets"
                          // onClick={this.navigateAddPets}
                          style={webStyle.addPetsText} 
                          >
                          Add pets
                        </Typography>
                      </Grid >
                    </Grid>
                  </Grid>
                  
                </Grid>

              </Grid>
            </Box>
          </Box>
        </ClickAwayListener>
      </>
    )
  }


  renderDogRoomtype = () => {
    return (
      <ClickAwayListener
         data-test-id="clickawaydog"
         onClickAway={()=>this.setState({roomopenfordog:false})}
      >
      <Box style={{
        backgroundColor: "#fff", width: "41%", zIndex: 5, position: "absolute",
        boxShadow: "rgba(67, 134, 161, 0.15) 0px 8px 10px -5px", padding: "20px"

      }}>
        <FormControl component="fieldset"
          style={{ width: "100%" }}
        >
          <RadioGroup
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "113%",
              marginLeft: "-38px"
            }}
            row
            onChange={(e: any) => {
              this.setState({ roomTypeDog: e.target.value });
            }}
            data-test-id='radioGrp'
            value={this.state.roomTypeDog}
          >


            <FormControlLabel data-test-id='radio1'
              style={{
                backgroundColor: this.state.roomTypeDog === "Single Suite" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"
              }}
              value="Single Suite" control={<Radio style={{ color: "#224351" }} />} label="Single Suite" />
            <FormControlLabel data-test-id='radio2'
              style={{
                backgroundColor: this.state.roomTypeDog === "Double Suite" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"
              }}
              value="Double Suite" control={<Radio style={{ color: "#224351" }} />} label="Double Suite" />

            <FormControlLabel data-test-id='radio3'
              style={{
                backgroundColor: this.state.roomTypeDog === "Luxury Villa" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"

              }}
              value="Luxury Villa" control={<Radio style={{ color: "#224351" }} />} label="Luxury Villa" />
          </RadioGroup>
        </FormControl>
        {this.state.roomTypeDog === "Single Suite" ? this.renderRoomDetails(
          "Tailored Comfort for One:",
          "Designed with the utmost care, this spacious haven provides a serene environment for your furry friend to relax and unwind.",
          "One dog fit into the room",
          configJSON.roomprice
        ) : null}

        {this.state.roomTypeDog === "Double Suite" ? this.renderRoomDetails(
          "Perfect for Pairs:",
          "Created to accommodate two pets from the same household, this spacious and cozy suite offers separate resting areas while maintaining a sense of togetherness",
          "Two dogs fit into the room",
          configJSON.roomprice
        ) : null}

        {this.state.roomTypeDog === "Luxury Villa" ? this.renderRoomDetails(
          "Extravagance for Extra Large Groups:",
          "This accommodation is designed to cater to the needs of multiple pets, ensuring they have plenty of room to stretch, roam, and socialize.",
          "Two dogs fit into the room",
          configJSON.roomprice
        ) : null}

        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            data-test-id="dogroomclose"
            onClick={() => this.setState({ roomopen: false, roomopenfordog: false })}
            style={{ ...webStyle.savebuttonstyle, textDecorationLine: "underline", margin: "22px" } as React.CSSProperties}>Continue</Button>
        </Box>
      </Box>
      </ClickAwayListener>
    )

  }


  renderCatRoomtype = () => {
    return (
      <ClickAwayListener data-test-id="catroomaway" onClickAway={()=>this.setState({roomopen:false})}>
      <Box style={{
        backgroundColor: "#fff", width: "41%", zIndex: 5, position: "absolute",
        boxShadow: "rgba(67, 134, 161, 0.15) 0px 8px 10px -5px", padding: "20px"

      }}>
        <FormControl component="fieldset"
          style={{ width: "100%" }}
        >
          <RadioGroup
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "113%",
              marginLeft: "-38px"
            }}
            row
            onChange={(e: any) => {
              this.setState({ roomTypeCat: e.target.value });
            }}
            data-test-id='radioGrp1'
            value={this.state.roomTypeCat}
          >


            <FormControlLabel data-test-id='radio1'
              style={{
                backgroundColor: this.state.roomTypeCat === "Single Suite" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"
              }}
              value="Single Suite" control={<Radio style={{ color: "#224351" }} />} label="Single Suite" />
            <FormControlLabel data-test-id='radio2'
              style={{
                backgroundColor: this.state.roomTypeCat === "Double Suite" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"
              }}
              value="Double Suite" control={<Radio style={{ color: "#224351" }} />} label="Double Suite" />

            <FormControlLabel data-test-id='radio3'
              style={{
                backgroundColor: this.state.roomTypeCat === "Luxury Villa" ? "#B2E4F8" : "transparent",
                padding: "12px",
                borderRadius: "15px 15px 0px 0px"

              }}
              value="Luxury Villa" control={<Radio style={{ color: "#224351" }} />} label="Luxury Villa" />
          </RadioGroup>
        </FormControl>
        {this.state.roomTypeCat === "Single Suite" ? this.renderRoomDetails(
          "Tailored Comfort for One:",
          "Designed with the utmost care, this spacious haven provides a serene environment for your furry friend to relax and unwind.",
          "One dog fit into the room",
          configJSON.roomprice
        ) : null}

        {this.state.roomTypeCat === "Double Suite" ? this.renderRoomDetails(
          "Perfect for Pairs:",
          "Created to accommodate two pets from the same household, this spacious and cozy suite offers separate resting areas while maintaining a sense of togetherness",
          "Two dogs fit into the room",
          configJSON.roomprice
        ) : null}

        {this.state.roomTypeCat === "Luxury Villa" ? this.renderRoomDetails(
          "Extravagance for Extra Large Groups:",
          "This accommodation is designed to cater to the needs of multiple pets, ensuring they have plenty of room to stretch, roam, and socialize.",
          "Two dogs fit into the room",
          configJSON.roomprice
        ) : null}

        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
          data-test-id="catroomclose"
            onClick={() => this.setState({ roomopen: false, roomopenfordog: false })}
            style={{ ...webStyle.savebuttonstyle, textDecorationLine: "underline", margin: "22px" } as React.CSSProperties}>Continue</Button>
        </Box>
      </Box>
      </ClickAwayListener>
    )

  }

  renderRoomDetails = (typograhy1: string, typography2: string, typography3: string, array: any) => {
    return (
      <Box style={{
        width: "88%",
        backgroundColor: "#B2E4F8",
        height: "min-content",
        display: "flex",
        flexDirection: "column",
        padding: "20px 20px",
        zIndex: 5,
      }}>
        <Typography>

          {typograhy1}
        </Typography>

        <Typography style={{ marginTop: "15px" }}>
          {typography2}
        </Typography>

        <Typography style={{ marginTop: "15px", fontWeight: 700 }}>{typography3}</Typography>

        {array.map((value: { price: string, detail: string }) => {
          return (
            <>
              <Box style={{
                width: "97%", display: "flex", padding: "10px", justifyContent: "space-between",
                alignItems: "center", backgroundColor: "#fff", borderRadius: "8px", marginTop: "10px", fontFamily: "inter"
              }}>
                <span>{value.detail}</span>
                <span>{value.price}</span>
              </Box>
            </>
          )
        })}



      </Box>
    )
  }


  renderButtons = () => {
    return (
      <>
      <Box style={webStyle.buttonsbox as React.CSSProperties}>
        <Button style={webStyle.editbutton as React.CSSProperties}
        data-test-id="Editbtn"
          onClick={() => this.OnShoppingCart()}
        >Edit reservation</Button>
        <Button style={webStyle.cancelbutton as React.CSSProperties}
        data-test-id="Cancelbtn"
          onClick={()=>this.setState({iscancelVisible:true})}
        >Cancel the reservation</Button>
        

      
      </Box>
      <Box style={{ display: "flex", flexDirection: "column",  justifyContent: "space-evenly" }}>

    
    {this.state.serviceKey==="standard"?this.renderPolicy():this.renderPolicy1()}
    
    </Box>
    </>
    )
  }

  EditDogSection = () => {
    return (

      <Grid container style={webStyle.style6}>
        <label style={webStyle.labeledit as React.CSSProperties}>
          Select the room type for dog
        </label>
        <br />
        <Box style={{ width: "100%", marginTop: "10px" }}>

          <div
            style={{ ...webStyle.breedselect, position: "relative" } as React.CSSProperties}
            data-test-id="roomopenfordog"
            onClick={() => this.setState({ roomopenfordog: true })}
          >
            <span style={{ ...webStyle.countrySpan, textTransform: "none" } as React.CSSProperties}>{this.state.roomTypeDog}</span>
            <ExpandMoreIcon
            />
          </div>
          {this.state.roomopenfordog ? this.renderDogRoomtype() : null}

          <div
            style={webStyle.additionalselect as React.CSSProperties}
            onClick={this.dogserviceOpen}
          >
            <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <span style={{ ...webStyle.countrySpan, textTransform: "none" } as React.CSSProperties}>Add additional services</span>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.checkedServicesDog.map((value) => (
                  <>
                    <span style={{ ...webStyle.countrySpan, textTransform: "none", marginRight: "5px" } as React.CSSProperties}>{value}</span>
                  </>
                ))}
              </Box>
            </Box>
            <ExpandMoreIcon
            />
          </div>

          {this.renderAdditionalSerivice(this.state.checkedServicesDog,configJSON.additionalservice,this.handleServiceCheckBoxDog,this.state.anchorElDodservice)}
        </Box>
      </Grid>

    )
  }

  EditCatSection = () => {
    return (

      <Grid container style={webStyle.style6}>
        <label style={webStyle.labeledit as React.CSSProperties}>
          Select the room type for cats
        </label>
        <br />
        <Box style={{ width: "100%", marginTop: "10px" }}>

          <div
          data-test-id="catroomopen"
            style={{ ...webStyle.breedselect, position: "relative" } as React.CSSProperties}
            onClick={() => this.setState({ roomopen: true })}
          >
            <span style={{ ...webStyle.countrySpan, textTransform: "none" } as React.CSSProperties}>{this.state.roomTypeCat}</span>
            <ExpandMoreIcon
            />
          </div>

          {this.state.roomopen ? this.renderCatRoomtype() : null}

          <div
            style={webStyle.additionalselect as React.CSSProperties}
            onClick={this.catserviceOpen}
          >
            <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <span style={{ ...webStyle.countrySpan, textTransform: "none" } as React.CSSProperties}>Add additional services</span>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.checkedServicesCat.map((value) => (
                  <>
                    <span style={{ ...webStyle.countrySpan, textTransform: "none", marginRight: "5px" } as React.CSSProperties}>{value}</span>
                  </>
                ))}
              </Box>
            </Box>
            <ExpandMoreIcon
            />
          </div>

          {this.renderAdditionalSerivice(this.state.checkedServicesCat,configJSON.additionalservice,this.handleServiceCheckBoxCat,this.state.anchorElCatservice)}
        </Box>
      </Grid>

    )
  }



  renderEditUi = () => {
    return (
      <Box style={webStyle.Editbox}>

        <Box style={{ display: "flex", width: "110%" }}>
          <Grid
            lg={6}
            item
            xs={12}
          >


            <label
              style={webStyle.labeledit as React.CSSProperties}
            >
              Check in
            </label>
            <br />
            <Box style={{ width: "34%" }}>


              <CustomCalendar1 style={{ marginTop: "5px", position: "relative" }}>
                <CustomDate
                  data-test-id="dogbirthdate"
                // onChange={this.handleBirthChange}
                // value={this.state.dogbirthdate}
                readOnly={false}
                />
                <CalendarTodayIcon
                  style={{
                    top: "17%",
                    cursor: "pointer",
                    position: "absolute",
                    left: "238%",
                    zIndex: 1,
                    color: "#334155",
                  }}
                />
              </CustomCalendar1></Box>

          </Grid>

          <Grid
            lg={6}
            item
            xs={12}
          >

            <label
              style={webStyle.labeledit as React.CSSProperties}
            >
              Check out
            </label>
            <br />
            <Box style={{ width: "34%" }}>


              <CustomCalendar1 style={{ marginTop: "5px", position: "relative" }}>
                <CustomDate
                  data-test-id="dogbirthdate"
                // onChange={this.handleBirthChange}
                // value={this.state.dogbirthdate}
                readOnly={false}
                />
                <CalendarTodayIcon
                  style={{
                    top: "17%",
                    cursor: "pointer",
                    position: "absolute",
                    left: "238%",
                    zIndex: 1,
                    color: "#334155",
                  }}
                />
              </CustomCalendar1></Box>

          </Grid>
        </Box>

        <Grid container style={webStyle.style5}>
          <label style={webStyle.labeledit as React.CSSProperties}>
            Add pet
          </label>
          <br />
          <Box style={{ width: "100%" }}>

            <div
              style={webStyle.breedselect as React.CSSProperties}
            onClick={this.openPet}
            >
              <span style={{ ...webStyle.countrySpan, textTransform: "none" } as React.CSSProperties}>2 Dog and 1 Cat</span>
              <ExpandMoreIcon
              />
            </div>
            {this.state.petPopUp===true ? this.renderPetInformation():null}
          </Box>
        </Grid>

        <Box style={{ width: "104%", display: "flex", alignItems: "center", marginTop: "7%", justifyContent: "space-between" }}>
          <Typography>Select for Dog</Typography>
          <Divider style={{ backgroundColor: "#B2E4F8", width: "80%", height: "2px" }} />
        </Box>

        {this.EditDogSection()}

        <Box style={{ width: "104%", display: "flex", alignItems: "center", marginTop: "7%", justifyContent: "space-between" }}>
          <Typography>Select for Cat</Typography>
          <Divider style={{ backgroundColor: "#B2E4F8", width: "80%", height: "2px" }} />
        </Box>
        {this.EditCatSection()}

        <Box style={webStyle.footerbuttons}>
          <Button style={webStyle.backbuttonstyle as React.CSSProperties}
          data-test-id="footerbackbtn"
            onClick={() => this.setState({ isEditOpen: false })}
          >
            <ArrowBackIosOutlinedIcon style={{ fontSize: "18px" }} />
            <span style={{ textDecorationLine: "underline", marginLeft: "10px" }}>back</span>
          </Button>

          <Button style={webStyle.savebuttonstyle as React.CSSProperties}
             onClick={()=>this.props.navigation.navigate("ReservationHistory")}
          >
            <span style={{ textDecorationLine: "underline", marginRight: "10px" }}>Save</span>
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "18px" }} />
          </Button>
        </Box>

      </Box>

    )
  }


  renderAdditionalSerivice = (service:any,additionalarray:any,onchange:any,anchorEl:any) => {
    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl as Element | null}
          keepMounted
          open={Boolean (anchorEl)}
          onClose={() => this.setState({ anchorElCatservice: null,anchorElDodservice:null })}
          data-test-id="handleSelectClose"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              width: "41.5%",
              padding: "0px 15px",
              cursor: "pointer",
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarColor: "transparent",
              scrollbarWidth: "thin",
              marginTop: "5px",
              borderRadius: "8px",
              borderBottomRightRadius: "50% !important"
            },
          }}
        >

          {additionalarray.map((value: { price: string; service: string; }) => (
            <React.Fragment >
              <Grid item style={{ ...webStyle.additionalgrid, backgroundColor: service.includes(value.service) ? "#E0F4FC" : "transparent" }}>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    data-test-id='checkBoxSelectServices'
                    onChange={(event) => onchange(event)}
                    value={`${value.service}`}
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                    color="default"
                    style={{ padding: "0px" }}
                  />
                  <Typography variant="body1" color="textSecondary">{value.service}</Typography>
                </Grid>
                <Typography variant="body1">
                  {value.price}
                </Typography>
              </Grid>
              <Divider style={{ backgroundColor: "#326478" }} />
            </React.Fragment>
          ))}

          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <Button
            data-test-id="continuecat"
              onClick={() => this.setState({ anchorElDodservice: null,anchorElCatservice:null })}
              style={{ ...webStyle.savebuttonstyle, textDecorationLine: "underline", margin: "22px" } as React.CSSProperties}>Continue</Button>
          </Box>

        </Menu>
      </>
    )

  }



  renderClaculation = () => {
    const { reservationData, totalcalculationdays } = this.state

    const selected_room_dog1 = this.state.dogRoomData ||[]



    const selected_room_cat1 = this.state.catRoomData || []

    const dogcount1 = (reservationData?.attributes.number_of_dogs)
    const catcount1 = (reservationData?.attributes.number_of_cats)

    const selected_service_dog1 = reservationData?.attributes.for_dog.additional_services_for_dog ||[]
    const selected_service_cat1 = reservationData?.attributes.for_cat.additional_services_for_cat ||[]

    const stripeAmount = reservationData?.attributes?.stripe_charges;
    return (
      <>
        {selected_room_dog1.length > 0 && selected_room_dog1.map((value: any) => {

          return (
            <>
              <Box style={{ width: "95%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for dog X ${totalcalculationdays} Days`}</Typography>
                <Typography style={webStyle.calculationText}>{`£${this.getRoomPrice(value.price, totalcalculationdays)}`}</Typography>
              </Box>
            </>
          )
        })}


        {selected_room_cat1.length > 0 && selected_room_cat1.map((value: any) => {
          return (
            <>
              <Box style={{ width: "95%", marginTop: "5px", display: "flex", justifyContent: "space-between",padding: "5px 5px" }}>
                <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for cat X ${totalcalculationdays} Days`}</Typography>
                <Typography style={webStyle.calculationText}>{`£${this.getRoomPrice(value.price, totalcalculationdays)}`}</Typography>
              </Box>
            </>
          )
        })}






        {selected_service_dog1.length > 0 && selected_service_dog1.map((value: any) => {
          return (
            <>
              <Box style={{ marginTop: "5px", width: "95%", padding: "5px 5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`${value.name} for ${dogcount1} dog`}</Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price * dogcount1 }`}</Typography>
              </Box>
            </>
          )
        })}

        {selected_service_cat1.length > 0 && selected_service_cat1.map((value: any) => {
          return (
            <>
              <Box style={{ padding: "5px 5px", marginTop: "5px", width: "95%", display: "flex", justifyContent: "space-between" }}>

                {value.service_provided_type==="OneTime"?<>
                  <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for ${this.getPetCount(catcount1, dogcount1)} pets`}</Typography>
                  <Typography style={webStyle.calculationText}>{`£${this.getPrice(value.price,catcount1, dogcount1, 1) }`}</Typography>
                  </>:<>
                  <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for ${this.getPetCount(catcount1, dogcount1)} pets X ${totalcalculationdays} Days`}</Typography>
                  <Typography style={webStyle.calculationText}>{`£${this.getPrice(value.price,catcount1, dogcount1, totalcalculationdays) }`}</Typography>
                  </>
              }
              </Box>
            </>
          ) })}

        {this.state.reservationData.attributes.hotel_information.pet_type === "daycare_services" && 
          <Box style={webStyle.calculationBox}>
             <Typography style={webStyle.calculationText}>
            {`£${this.state.displayPrice} X ${this.state.reservationData.attributes.pets.data.length} Pets ${this.getNumberOfWeeks()}`}
          </Typography>
          <Typography style={webStyle.calculationText}>
            {`£${this.getTotalPrice(stripeAmount, selected_room_dog1, selected_room_cat1,selected_service_cat1, totalcalculationdays, dogcount1, catcount1)}`}
          </Typography>
          </Box>
        }

        {stripeAmount && <Box style={webStyle.calculationBox}>
          <Typography style={webStyle.calculationText}>
            {configJSON.transactionFee}
          </Typography>
          <Typography style={webStyle.calculationText}>
            {`£${stripeAmount}`}
          </Typography>
        </Box>}
        <Divider style={{ backgroundColor: "#B2E4F8", margin:"10px 8px"}} />
        <Box style={{ width: "95%", padding: "10px 5px", marginTop: "20px", borderRadius: "8px", display: "flex", justifyContent: "space-between"}}>
         <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Total</Typography>
           <Typography style={webStyle.calculationText}>{`£${this.getTotalPrice(stripeAmount, selected_room_dog1, selected_room_cat1,selected_service_cat1, totalcalculationdays, dogcount1, catcount1)}`}</Typography>

       </Box>

      </>
    )

  }

  renderPolicy=()=>{
    return(
      <>
         {this.state.standardCancellation.length>0 &&
         this.state.standardCancellation.map((value:any)=>{
         return(
          <>
           <div
           style={{fontFamily:"inter",fontWeight:400}}
              dangerouslySetInnerHTML={{
                __html: value?.attributes?.description
              }}
            />
          </>
         )
             
         })
         }
      </>
    )
  }

  renderPolicy1=()=>{
    return(
      <Box style={{marginLeft:"10px"}}>

     

        <Typography>1. Until <span style={webStyle.spandata}>{this.state.tailoredCancellation?.free_cancellation_days} days</span> the pet owner can cancel their reservation free of charge.</Typography> 
        <Typography>2. Until <span style={webStyle.spandata}>{this.state.tailoredCancellation?.free_refund_days} days</span> the pet owner amend their reservation free of charge.</Typography> 
        <Typography>3. The pet owner will be charged <span style={webStyle.spandata}>{this.state.tailoredCancellation?.pre_payment_percent}%</span> as pre payment. </Typography> 
        <Typography>4. We will hold on to the <span style={webStyle.spandata}>{this.state.tailoredCancellation?.cancellation_hold_percent}%</span> of the booking charges if the pet owner doesn't turn up on pre payment.</Typography> 
        <Typography>5. We will hold on to the <span style={webStyle.spandata}>£{this.state.tailoredCancellation?.confirm_deposit_amount}</span> of the booking charges if the pet owner doesn't turn up on deposit.</Typography> 
        
         
      </Box>
    )
  }




  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ width: "100%", padding: "0px" }}>
          <CustomizedSnackbars toasterType={this.state.toasterType} openToaster={this.state.toasterStatus}
           message={this.state.toasterMessage}
           duration={2000}
          closeStatus={this.closeToaster} />
          <NavbarBox style={{ zIndex: 6, position: "relative" }}>
            <Navbar activeLink={6} navType="fullNavbar" />
          </NavbarBox>
          <Box sx={webStyle.mainWrapperStyle}>
            <Box style={webStyle.headingbox}>
              <Button  
                onClick={()=>this.props.navigation.navigate("ReservationHistory")}
              >
                <ArrowBackIosOutlinedIcon />
              </Button>
              <Typography style={{ fontWeight: 700, fontSize: "24px" }}>Edit the reservation and confirm</Typography>
            </Box>
            <Box style={{ display: "flex", width: "80%", columnGap: "30px" }}>
              <Box style={webStyle.contentbox}>
                {this.state.isEditOpen ? this.renderEditUi() : this.renderButtons()}
              </Box>

              <Box style={webStyle.calculationbox}>

                <Card style={webStyle.cardLayout}>
                  <img src={this.state.reservationData?.attributes?.images[0]?.url}
                    style={webStyle.media as React.CSSProperties}
                    data-test-id='error_image' />
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={webStyle.hotelName}>{this.state.reservationData?.attributes?.hotel_information?.name}</Typography>
                      </Grid>
                      <Grid item xs={12} container direction="row" alignItems="center">
                        <LocationOnOutlinedIcon />
                        <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>
                        {this.state.reservationData?.attributes?.hotel_information?.hotel_city},
                        {this.state.reservationData?.attributes?.hotel_information?.hotel_country_region}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={12} sm={6} >
                            <Typography variant="h6" style={webStyle.hotelName}>£{this.getDisaplyPrice(this.state.reservationData?.attributes)}</Typography>
                            <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>Starting from Per Day</Typography>
                          </Grid>
                          <Grid item >
                            <Grid container justifyContent="flex-end" style={{ width: "115px" }}>
                              <Grid item xs={12}>
                                <CustomRating rating={this.state.reservationData?.attributes?.hotel_information?.rating||0} />
                              </Grid>

                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.reservationData?.attributes?.hotel_information?.rating||0}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.reservationData?.attributes?.hotel_reviews_count} Reviews</Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box sx={webStyle.hoteldescribe}>
                   <Box style={webStyle.calculationbox2}>
                    {this.renderClaculation()}
                    </Box> 

                  </Box>
                </Card>

              </Box>
            </Box>


          </Box>

          <CustomsettingModal
                  isVisible={this.state.iscancelVisible}
                  hideModal={this.cancelclose}
                  callApi={this.handleCancelReservation}
                  typography1="Do you want to cancel this reservation"
                  typography2="Are you sure you want to cancel this reservation?"
                  buttontext1="Yes,Sure"
                  buttontext2="No,I don't"
            />

        </Container>
        <Footer color="#CCEDFB" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
        <Loader loading={this.state.ReservationLoader} />
      </ThemeProvider>

    );
  }
}


const NavbarBox = styled(Box)({
  "& header": {
    boxShadow: "none"
  }
})

const CustomCalendar1 = styled(Box)({

  "& .rmdp-input": {
    backgroundColor: "#E0F4FC",
    height: "40px",
    width: "265%",
    borderRadius: "8px",
    border: "solid 1px #B2E4F8",
    fontFamily: "inter"
  },
  "& .rmdp-header": {
    backgroundColor: "#CCEDFB",
    borderRadius: 8,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "inter"
  },
  "& .rmdp-week-day": {
    color: "#000",
    fontFamily: "inter"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    fontFamily: "inter"
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "inter"
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    borderColor: "#EA0C78",
    fontFamily: "inter"
  },
  "& .rmdp-arrow": {
    borderColor: "#EA0C78"
  },

  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78",
    fontFamily: "inter"
  }

})

const webStyle = {
  calculationBox: {
    padding: "5px 5px",
    marginTop: "5px",
    width: "95%",
    display: "flex",
    justifyContent: "space-between"
  },
  calculationText: { 
    fontSize: "14px", 
    fontWeight: 700 
  },
  mainWrapperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "200px",
    background: "#CCEDFB",
    minHeight: "100vh",
    marginTop: "100px"
  },
  headingbox: {
    width: "80%",
    backgroundColor: "#fff",
    height: "110px",
    zIndex: 4,
    boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px",
    display: "flex",
    alignItems: "center",

  },
  contentbox: {
    backgroundColor: "#fff",
    width: "60%",
    
    borderRadius: "0px 0px 8px 8px",
    height:"min-content",
    padding:"20px 10px"

  },
  calculationbox: {
    backgroundColor: "#fff",
    width: "40%",
    height: "max-content",
    zIndex: 5,
    borderRadius: "0px 0px 8px 8px"
  },
  buttonsbox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    minHeight:"180px"
  },
  editbutton: {
    width: "55%",
    height: "56px",
    backgroundColor: "#EA0C78",
    color: "#fff",
    fortSize: "16px",
    fontWeight: 700,
    textTansform: "none",
    borderRadius: "8px",
    textTransform: "none"
  },
  cancelbutton: {
    width: "55%",
    height: "56px",
    border: "1px solid #EA0C78",
    color: "#EA0C78",
    fortSize: "16px",
    fontWeight: 700,
    textTansform: "none",
    borderRadius: "8px",
    textTransform: "none"
  },
  cardLayout: {
    marginTop: "-70px",
    marginBottom: 30,
    padding: "0px 20px",
    boxShadow: "none",

  },
  hotelName: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 28
  },
  locationName: {
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 24
  },
  font14400: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 400,
    marginRight: 10
  },
  font14700: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 700,
  },
  hoteldescribe: {
    maxWidth: 522,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "spaca-between",
    // backgroundcolor: "yellow",
    gap: 20
  },
  media: {
    height: 244,
    borderRadius: 8,
    maxWidth: 420,
    width: '100%',
    margin: 'auto',
    objectFit: 'cover'
  },
  cardPosition: {
    position: 'relative',
    top: '-70px',
    [theme.breakpoints.down('xs')]: {
      top: '0px',
    }
  },
  calculationbox2: {
    width: 400,
    // height: 190,
    border: "1px solid #B2E4F8",
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    // padding: "20px",

  },
  Editbox: {
    width: "90%",
    // height:"500px",
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "40px 20px"
  },
  labeledit: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#334155",
    fontFamily: "inter",
    marginBottom: "5px",
  },
  breedselect: {
    height: "38px",
    padding: "0px 10px",
    width: "98%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  additionalselect: {
    height: "58px",
    padding: "0px 10px",
    width: "98%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginTop: "10px"
  },
  countrySpan: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 400, fontFamily: "inter",
    textTransform: "capitalize"
  },

  style5: {
    margin: "10px 0px",
    width: "102.5%"
  },

  style6: {
    width: "102.5%",
    margin: "25px 0px",
  },

  footerbuttons: {
    width: "104%",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  backbuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#74748B"
  },
  savebuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#EA0C78"
  },
  additionalgrid: {
    display: "flex", height: "50px", alignItems: "center", padding: "0px 10px", justifyContent: "space-between",

  },
  modalPopup: {
    // position: "absolute",
    zIndex: 9,
    width: "100%",
    minHeight: "170px",
    height: 'object-fit',
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: boxShadows.whiteShadow,
  },
  modalPopupContent: {
    padding: "2rem "
  },
  petInfoText: {
    fontWeight: theme.typography.fontWeightRegular
  },
  addPetsText: {
    fontWeight: theme.typography.fontWeightBold
  },
  petListBox: {
    width: 630,
    height: 72,
    padding: "4px, 10px, 4px, 10px",
    gap: 20,
    borderBottom:`4px solid ${theme.palette.primary.main}`,
    boxShadow:boxShadows.blueShadow,
    marginBottom:10
  },
  headingText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black
  },
  headingBlack: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightRegular
  },
  spandata:{
    fontWeight:700,
    color:"#000"
  }

};

// Customizable Area End
