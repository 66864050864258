Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "bx_block_stripe_integration/payments"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.verifyPayment='bx_block_stripe_integration/payment_methods'
  exports.confirmPaymentIntent="bx_block_stripe_integration/confirm";
  exports.confirmSubscriptionPaymentIntent="bx_block_user_subscriptions/subscriptions/payment"
  exports.confirmSubscriptionConfirmPaymentIntent="bx_block_user_subscriptions/subscriptions/confirm"

  exports.paymentErrorText={
    failed:"Payment Failed",
    verifyCard:"Unable to verify the card,Please enter different card details",
    paymentIntent:"Unable to make payment"
  }
  // Customizable Area End
  