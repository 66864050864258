import React, { useEffect, useState, useRef } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import { getStorageData } from "../../../framework/src/Utilities";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { config } from "./config.web";

import StripePaymentsView from "./StripePaymentsView.web";

// Customizable Area Start
export const configs = require("./config");
import { CancellationPolicyData, CardToken, ConfirmPayment, PaymentMethod, PaymentMethodDetails,PaymentIntent } from "./types";

// Customizable Area End

export interface ViewProps {
  // Customizable Area Start
  getCardTokenDetails :Function
  policyType?:CancellationPolicyData
  testID: string;
  stripePromise: any;
  stripeClientSecret: string | undefined;
  errorString: string;
  setOrderNumber: (_: number) => void;
  orderNumber: number | undefined;
  submitOrderNumber: () => void;
  actionResult: string | undefined;
  stripeInitialised: boolean;
  isInStripeCallback: boolean;
  onHandleSubmit: (
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => void;
  submitOrderNumberButtonViewProps: { value: string };
  submitPaymentButtonViewProps: { value: string };
  loadingViewProps: { value: string };
  orderIdViewProps: { value: string };
  stripeMessageViewProps: { successValue: string; errorValue: string };
  orderPrice:string;
  paymentPageType?:string;
  backSubcription?:()=>void
  paymentTriggered?: () => void;
  pageComingFrom?:string;
  loading: boolean;
  toasterStatus: boolean
  // Customizable Area End
}

export interface ControllerProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  orderId?:string,
  orderPrice:string,
  connectAccoutnID:string
  getPaymentStatus:Function;
  policyType?:CancellationPolicyData
  transactionFees?:string
  paymentPageType?:string;
  backSubcription?:()=>void
  hotelId?: number;
  paymentTriggered?: () => void;
  changePlanStatus?:boolean
  pageComingFrom?:string;

  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const StripePayments = ({
  // Customizable Area Start
  navigation, id,orderId,orderPrice,connectAccoutnID,getPaymentStatus,policyType,
  transactionFees,
  paymentPageType,
  backSubcription,
  hotelId,
  paymentTriggered,
  changePlanStatus,
  pageComingFrom

  // Customizable Area End

}: ControllerProps) => {
  // Customizable Area Start
  const getPaymentMethodsCallId = useRef<string>("");
  const apiCallIdVerifyPaymentCard = useRef<string>("");
  const confirmPaymentIntentApiCallId= useRef<string>("");
  // Customizable Area End

  // Customizable Area Start
  const params = new URLSearchParams(window.location.search);
  const returnedPaymentIntentClientSecret = params.get(
    "payment_intent_client_secret"
  );
  const isInStripeCallback = Boolean(returnedPaymentIntentClientSecret);
  const [orderNumber, setOrderNumber] = useState<number | undefined>(undefined);
  const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<
    string | undefined
  >(undefined);
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<
    string | undefined
  >(undefined);
  const [stripeClientSecret, setStripeClientSecret] = useState<
    string | undefined
  >(undefined);
  const [stripeCustomerId, setStripeCustomerId] = useState<string | undefined>(
    undefined
  );
  const [stripeInitialised, setStripeInitialised] = useState<boolean>(false);
  const [stripeActionResultMessage, setStripeActionResultMessage] = useState<
    string | undefined
  >(undefined);
  const [errorString, setErrorString] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [toasterStatus, setToasterStatus] = useState<boolean>(false);
 
  // Customizable Area End

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const [stripePromise] = useState(loadStripe(config.stripeKey));

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  const restoreSessionFromLocalStorage = () => {
    const persistedAuthToken = getStorageData("authToken", false);
    if (persistedAuthToken) {
      const messsage: Message = new Message(
        getName(MessageEnum.SessionSaveMessage)
      );
      messsage.addData(
        getName(MessageEnum.SessionResponseToken),
        persistedAuthToken
      );
      sendBlockMessage(messsage);
    }
  };
  // Customizable Area End

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
      if (resToken) {
        setUserAppAuthenticationToken(resToken);
      } 
    }else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (apiRequestCallId === getPaymentMethodsCallId.current ||from === "UNIT_TEST") {
       paymentMethodApiResponce(responseJson)
      }else if (apiRequestCallId === apiCallIdVerifyPaymentCard.current ||from ==="VERIFY_TEST") {
        handleverifyPaymentResponce(responseJson)
      }
      else if (apiRequestCallId === confirmPaymentIntentApiCallId.current ||from ==="CONFIRM_PAYMENT_TEST") {
       handleConfirmPaymentResponce(responseJson)
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const handleverifyPaymentResponce=(responseJson:PaymentMethod)=>{
    if(responseJson&&!responseJson.errors){
      if (paymentPageType === "subscription") {
        
        createSubscriptionPaymentIntent(responseJson.data)
      } else {
        createPaymentIntent(responseJson.data)

      }
    }   
  }
  const paymentMethodApiResponce=(responseJson:PaymentIntent)=>{
    if (responseJson && !responseJson.errors) {
      setStripeClientSecret(responseJson?.data?.attributes?.client_secret);
      setStripeCustomerId(responseJson?.data?.attributes?.customer);
      if (paymentPageType === 'subscription') {
        setStripePaymentIntentId(responseJson?.transaction?.paymentintent_id);
      } else {
        setStripePaymentIntentId(responseJson?.data?.id);
      }
    } else if (responseJson && responseJson.errors) {
      setToasterStatus(true);
      setLoading(false);
        setErrorString(configs.paymentErrorText.paymentIntent);
    }
  }
  const handleConfirmPaymentResponce=(responseJson:ConfirmPayment)=>{
    if (responseJson && !responseJson.errors) {
      if (responseJson?.payment_intent?.status === "succeeded") {
        setLoading(false);
          getPaymentStatus(true)
      } else {
        setLoading(false);
          getPaymentStatus(false)
      }

    } else {
      if (responseJson && responseJson.errors) {
        getPaymentStatus(false)
        setLoading(false);
          setErrorString(configs.paymentErrorText.failed)
      }
    }
  }
  const verifyCardDetails = async(token:CardToken) => {
    const loginToken= await getStorageData('authToken')
    const headers = {
      "Content-Type": configs.apiContentType,
      token:loginToken
    };
    const httpBody = {
      "payment_method": {
        "card_token": token.id
      }
    }

    sendNetworkRequest(
      apiCallIdVerifyPaymentCard,
        configs.confirmPaymentMethod,
        configs.verifyPayment,
        headers,
        httpBody
    );
  };
  const createPaymentIntent = async (CardDetails:PaymentMethodDetails) => {
    const loginToken= await getStorageData('authToken')

    let httpBody=    {
      "payment": {
        "payment_method_id":CardDetails.id,
        "transaction_fee":transactionFees

      },
       "total_amount":orderPrice.replace("£",''),
      "connected_account_id":connectAccoutnID,
      "order_id":orderId,
    }

    sendNetworkRequest(
      getPaymentMethodsCallId,
      config.createPaymentIntentMethod,
      configs.stripePaymentIntent,
      {
        "Content-Type": config.creatPaymentIntentApiContentType,
        token: loginToken,
      },
      httpBody
     
    );
  };
  const confirmPaymentIntent = async (paymentIntent:any) => {

    const loginToken= await getStorageData('authToken')

    let httpBody=    {
      "payment_intent_id":paymentIntent,
    }

    sendNetworkRequest(
      confirmPaymentIntentApiCallId,
      config.createPaymentIntentMethod,
      configs.confirmPaymentIntent,
      {
        "Content-Type": config.creatPaymentIntentApiContentType,
        token: loginToken,
      },
      httpBody
     
    );
  };
  const createSubscriptionPaymentIntent = async (CardDetails: PaymentMethodDetails) => {
    const loginToken = await getStorageData('authToken')
    let subscriptionBody = {
      "user_subscription": {
        "payment_method_id": CardDetails.id,
        "hotel_id": hotelId,
        "change_plan": changePlanStatus
      }
    }

    sendNetworkRequest(
      getPaymentMethodsCallId,
      config.createPaymentIntentMethod,
      configs.confirmSubscriptionPaymentIntent,
      {
        "Content-Type": config.creatPaymentIntentApiContentType,
        token: loginToken,
      },
      subscriptionBody

    );
  };
  const confirmPaymentSubscription = async (paymentIntent: string) => {

    const loginToken = await getStorageData('authToken')

    let httpBody = {
      "user_subscription": {
        "payment_intent_id": paymentIntent,
        "hotel_id": hotelId,
        "change_plan": changePlanStatus
      }
    }

    sendNetworkRequest(
      confirmPaymentIntentApiCallId,
      config.createPaymentIntentMethod,
      configs.confirmSubscriptionConfirmPaymentIntent,
      {
        "Content-Type": config.creatPaymentIntentApiContentType,
        token: loginToken,
      },
      httpBody

    );
  };

  const checkPaymentResult = async () => {
    const stripe = await stripePromise;
    if (!stripe || !returnedPaymentIntentClientSecret) {
      return;
    }
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      returnedPaymentIntentClientSecret
    );
    if (error) {
      return;
    } else if (paymentIntent) {
      const userNotification = `STRIPE OUTCOME: ${paymentIntent.status}`;
      setStripeActionResultMessage(userNotification);
    }
  };

  const getCardTokenDetails=(token:CardToken)=>{
    setLoading(true)
    verifyCardDetails(token)
  }
  // Customizable Area End

  
  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));
    // Customizable Area Start
    getToken();
    // Customizable Area End
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);
  // Customizable Area Start

  useEffect(() => {
    checkPaymentResult();
  }, [userAppAuthenticationToken]);

  const submitOrderNumber = () => {
    if (userAppAuthenticationToken) {
    } else {
      setErrorString("no user token available. please log in");
    }
  };

  useEffect(() => {
    if (paymentPageType !=='subscription') {
    if (stripeClientSecret && stripePaymentIntentId && stripeCustomerId) {
      confirmPaymentIntent(stripePaymentIntentId)
      setStripeInitialised(true);
    }
  }
  }, [stripeClientSecret, stripePaymentIntentId, stripeCustomerId]);

  useEffect(() => {
    if (paymentPageType==='subscription') {
      if(stripePaymentIntentId){

        confirmPaymentSubscription(stripePaymentIntentId)
      }
    }
  }, [stripePaymentIntentId]);
  // Customizable Area End

  // Customizable Area Start
  const onHandleSubmit = async (
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => {
    if (!stripe || !stripeElements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements: stripeElements,
      confirmParams: { return_url: window.location.href },
    });

    if (result.error) {
      setStripeActionResultMessage(result.error.message);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  const orderIdViewProps = {
    value: config.orderId,
  };

  const submitOrderNumberButtonProps = {
    value: config.submitText,
  };

  const submitPaymentButtonProps = {
    value: config.submitText,
  };

  const loadingViewProps = {
    value: config.loading,
  };

  const stripeMessageViewProps = {
    successValue: config.stripeSuccessMessage,
    errorValue: config.stripeErrorMessage,
  };
  // Customizable Area End

  // Customizable Area Start
  const viewProps: ViewProps = {
    testID: "",
    errorString,
    stripePromise: stripePromise,
    stripeClientSecret: stripeClientSecret,
    setOrderNumber: setOrderNumber,
    orderNumber: orderNumber,
    submitOrderNumber: submitOrderNumber,
    actionResult: stripeActionResultMessage,
    stripeInitialised: stripeInitialised,
    isInStripeCallback: isInStripeCallback,
    onHandleSubmit: onHandleSubmit,
    submitOrderNumberButtonViewProps: submitOrderNumberButtonProps,
    submitPaymentButtonViewProps: submitPaymentButtonProps,
    loadingViewProps: loadingViewProps,
    orderIdViewProps: orderIdViewProps,
    stripeMessageViewProps: stripeMessageViewProps,
    getCardTokenDetails:getCardTokenDetails,
    policyType:policyType,
    orderPrice:orderPrice,
    paymentPageType:paymentPageType,
    backSubcription:backSubcription,
    paymentTriggered: paymentTriggered,
    pageComingFrom:pageComingFrom,
    loading,
    toasterStatus
  };

  return <StripePaymentsView {...viewProps} />;
};

export default StripePayments;
// Customizable Area End
