// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.itemListApiContentType = "application/json";
exports.urlGetItemList = "bx_block_order_reservations/reservation_services"
exports.itemListApiMethodType = "GET";

exports.urlSecureReservationList = "bx_block_order_reservations/account_orders";
exports.secureReservationApiMethodType = "POST";

exports.urlGetReservationHistory = "bx_block_order_reservations/account_orders"
exports.getReservationHistoryApiMethodType = "GET";

exports.urlEditReservation = "bx_block_order_reservations/account_orders/"
exports.editReservationApiMethodType = "PUT";

exports.urlCancelReservation = "bx_block_order_reservations/account_orders/"
exports.cancelReservationApiMethodType = "DELETE";
exports.allRoomsReservationUrl = "bx_block_custom_form/rooms/bo_rooms/"

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reservations";
exports.labelBodyText = "Reservations Services List";
exports.txtInputPlaceholder = "quantity";
exports.totalPrice = "Total Price";
exports.edit = "Edit";
exports.delete = "Cancel";
exports.reservationStatus = "Reservation Status";

exports.btnExampleTitle = "Secure Reservation";
exports.btnEditTitle = "Edit";
exports.btnCancelTitle = "Cancel";
exports.btnHistoryExampleTitle = "Go To Reservation History";
exports.textquantityplaceholder = "quantity";

exports.txtOrderId = "Order id";
exports.orderPlacedDate = "Order Date";
exports.sortBy = "Sort By";
exports.search = "Search";
exports.accountsAPiEndPoint = "bx_block_login/logins"

exports.outlookRedirection = 'bx_block_calendar/auth/microsoft/'
exports.calendarSyncApiMethod = "POST";
exports.calendarSyncApiEndpoint = "bx_block_calendar/events/sync";

exports.additionalservice = [
  {price:"$91",service:"Dog service1"},
  {price:"$92",service:"Dog service2"},
  {price:"$93",service:"Dog service3"},
  {price:"$94",service:"Dog service4"},
  {price:"$95",service:"Dog service5"},
  {price:"$96",service:"Dog service6"},
  {price:"$97",service:"Dog service7"},
]

exports.roomprice=[
  {detail:"The room price of one dog per day",price:"£20"},
]

// Api End Points
exports.HotelListEndPoint="bx_block_custom_form/all_properties";
exports.reservationsEndPoint="bx_block_shopping_cart/business_reservations?status";
exports.updateOrderEndPoint="bx_block_shopping_cart/business_reservations";
exports.previewOrderEndPoint="bx_block_shopping_cart/order_creations"



exports.filterPopup={
  heading:"Filters",
  subHeading:"Select any type of place.",
  calenerInputLabel:"Date Range",
  petsInputLabel:"Number of pets",
  priceInputLabel:"Price Range",
  roomInputLabel:"Room type",
  roomsInputLabel:"Rooms",
  search:"Search"

}
exports.export = "Export";
exports.delete="Delete"; 
exports.calendar="calendar"; 
exports.csvEndPoint = "bx_block_shopping_cart/order_creations/order_details_by_user.csv";
exports.csvURL="bx_block_shopping_cart/completed_orders";
exports.textCSVFileName="orderDetailsByUser";
exports.roomEndpoint="bx_block_custom_form/rooms"; 
exports.getPaymentDetailsEndpoint="pending_payment_details"
exports.hotelRoomEndpoint= "bx_block_custom_form/rooms?hotel_id={hotelId}";

exports.pendingApproveErrorMessage="You cannot approve after the actual check-in date";
exports.upcomingApproveErrorMessage="You cannot check in before the actual check in date";
exports.transactionFee = "Transaction Fee";
exports.noReservation="No Reservations Found"
// Customizable Area End
