import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, styled, withStyles } from "@material-ui/core/styles";
import { theme } from "../../../components/src/theme";
import SideNavbar from "../../../components/src/SideNavbar";
import Footer from "../../../components/src/Footer.web";
import { addimg } from "../../contentmanagement/src/assets";
import { BOheaderbg, deleteaccount, editPencilIcon } from "./assets"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import Loader from "../../../components/src/Loader";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class Settings2BO extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getValError2 = (field: string[]): React.ReactNode | undefined => {
    const isInValidbo = field.findIndex((item) => this.state.error[`${item}Error`]);

    if (isInValidbo >= 0) {
      return (
        <span style={{ color: "red" }}>{this.state.MesErrProfile[`${field[isInValidbo]}`]}</span>
      );
    }
    return undefined;
  };

  RenderPassword = () => {

    const {
      confirmPassword,
      showPassword,
      showYourPassword,
      showoldPassword,
      password,
      oldpassword
    } = this.state;

    const passwordValidationPo = this.validatePasswordPo();
    return (
      <>
        <Grid

        >
          <label
            style={webStyle.labelsEmailPassword as React.CSSProperties}
          >
            Old Password
          </label>
          <br />

          <Typography variant="h1" color="initial" />

          <Custompassinput
            style={{ ...webStyle.inputEmailPassword } as React.CSSProperties}
            placeholder="Enter old password"
            type={showoldPassword ? "text" : "password"}
            value={oldpassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={this.handleToggleoldPassword}
                  data-test-id="togglePassword"
                >
                  {showoldPassword ? (
                    <VisibilityIcon
                      style={{
                        cursor: "pointer",
                        color: "#94A3B8",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#94A3B8",
                        cursor: "pointer"
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            variant="outlined"
            data-test-id="old_password"
            onChange={this.handleChangeoldPassword}

          />
          <Typography color="error" variant="body1" style={webStyle.erroMessage}>{this.state.errorpassword}</Typography>

          <label
            style={webStyle.labelsEmailPassword as React.CSSProperties}
          >
            New Password
          </label>
          <br />

          <Typography variant="h1" color="initial" />

          <Custompassinput
            onChange={this.handleChangePassword}
            style={{ marginBottom: "10px", ...webStyle.inputEmailPassword } as React.CSSProperties}
            value={password}
            data-test-id="password_change"
            type={showPassword ? "text" : "password"}
            variant="outlined" placeholder="Enter new password"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  data-test-id="togglePassword"
                  position="end"
                  onClick={this.handleTogglePassword}
                >
                  {showPassword ? (
                    <VisibilityIcon
                      style={webStyle.visiblestyle}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={webStyle.visiblestyle}
                    />
                  )}

                </InputAdornment>
              ),
            }}

          />
          {this.state.sameoldpass ? <Typography style={{ color: "red", marginTop: "-10px" }}>Old and New Password can not be same</Typography> : null}
          <br />
          <label
            style={webStyle.labelsEmailPassword as React.CSSProperties}
          >
            Confirm Password
          </label>
          <br />
          <Custompassinput
            InputProps={{
              endAdornment: (
                <InputAdornment
                  data-test-id="handle_pas_tgl"
                  position="end"
                  onClick={this.handleYourTogglePassword}
                >
                  {showYourPassword ? (
                    <VisibilityIcon
                      style={webStyle.visiblestyle}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={webStyle.visiblestyle}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            placeholder="Confirm new password"
            style={{ ...webStyle.inputEmailPassword, marginBottom: "10px" } as React.CSSProperties}
            variant="outlined"
            data-test-id="confirm_password"
            onChange={this.handleConfirmPassword}
            type={showYourPassword ? "text" : "password"}
            value={confirmPassword}
            name="confirmPassword"
          />
          {this.state.samepass ? null : <Typography style={{ color: "red", marginTop: "-10px" }}>New and Confirm Password Should be same</Typography>}
          <div style={{ marginTop: "20px" }}>
            <Typography style={{ fontSize: "16px", fontWeight: 400, fontFamily: "inter" }}>Your password must contain</Typography>
            {[
              {
                isValid: passwordValidationPo.isLowerCaseLetter,
                message: "At least one lowercase letter",
              },
              {
                isValid: passwordValidationPo.isCapitalLetter,
                message: "At least one capital letter",
              },
              {
                isValid: passwordValidationPo.isMinLength,
                message: "Minimum character length is 8 characters",
              },
              {
                isValid: passwordValidationPo.isNumber,
                message: "At least one number",
              },
              {
                isValid: passwordValidationPo.isSpecialChracter,
                message: "At least one special character"
              }
            ].map((validItem, idx) => (
              <Grid
                key={idx}
                style={{
                  marginTop: "7px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {validItem.isValid ? (
                  <CheckCircleIcon
                    style={{
                      color: "#34D399",
                      marginRight: "5px",
                    }}
                  />
                ) : (
                  <RemoveCircleIcon
                    style={{
                      marginRight: "5px",
                      color: "#DC2626",
                    }}
                    data-test-id='removeIcon'
                  />
                )}
                <span style={{ fontSize: "14px", fontFamily: "inter" }}>{validItem.message}</span>
              </Grid>
            ))}
          </div>
          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", height: 50 }}>
            <Button disabled={!passwordValidationPo.isValid || !this.state.samepass} style={{ ...webStyle.linkstyle, textTransform: "none", color: !passwordValidationPo.isValid || !this.state.samepass ? "grey" : "#EA0c78", marginRight: "0px" }} onClick={this.openpassmodal}>Save</Button>
          </Box>


        </Grid>


      </>
    )
  }





  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <BussinessOwnerSideBar active={6} navigation={this.props.navigation} profilePhoto={this.state.updatedProfilePhotoBo} />
          <div style={webStyle.seetingBoContainer as React.CSSProperties} >
            <Container maxWidth={false}>
              <CustomizedSnackbars
                duration={20000}
                openToaster={this.state.flashslack}
                message={"Profile updated successFully!"}
                toasterType={"success"} closeStatus={this.closeStatus} />
              <CustomizedSnackbars
                duration={20000} openToaster={this.state.passslack} message={"Password Updated Sucessfully"} toasterType={"success"} closeStatus={this.closeStatus} />
              <Grid item xs={12} style={webStyle.outerlayout as React.CSSProperties}>
                <Grid container direction="row" justifyContent="flex-start" style={webStyle.innerContainer} >

                  <Grid item xs={12} >
                    <Box style={webStyle.multiscreens}>
                      <Box style={{ width: "100%", height: "100%", backgroundColor: "#fff", display: "block" }}>


                        <Typography style={{ fontSize: "25px", color: "#000", fontWeight: 700 }}>Reset Password</Typography>

                        {this.RenderPassword()}



                        <Typography style={{ fontSize: 24, fontWeight: 700, color: "#000" }}>Delete Account</Typography>
                        <Typography style={{ fontSize: 14, fontWeight: 400, color: "#000", marginTop: "25px" }}>Deleting your account will remove all of your information from our database.<br />This cannot be undone.</Typography>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "25px" }}>
                          <Button
                            style={{ ...webStyle.linkstyle, marginRight: "0px" } as React.CSSProperties}
                            onClick={this.openModal}
                            data-test-id="deleteAccountButton"
                          >Delete Account</Button>
                        </Box>


                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '30px' }}>
                  <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
                </Grid>
              </Grid>
            </Container>
          </div>

          <Loader loading={this.state.pageLoader} />
        </ThemeProvider>
        <CustomsettingModal
          isVisible={this.state.ispassvisible}
          hideModal={this.hideModal}
          callApi={() => this.ResetPasswordForPO("BO")}
          typography1="Reset Password"
          typography2="Are you sure want to reset password?"
          buttontext2="No,I don't"
          buttontext1="Yes,Sure"
        />
        <CustomsettingModal
          isVisible={this.state.isVisible}
          hideModal={this.hideModal}
          callApi={this.callApiForDelete}
          typography1="Delete Account"
          typography2="Deleting your account will remove all of your information from our database."
          typography3="This cannot be undone."
          imgsrc={deleteaccount}
          typography4="Please Block your 2 existing ongoing reservations and try again."
          buttontext1="Yes, Delete"
          buttontext2="No, don’t Delete"
        />
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start



const Custominfodiv: any = styled(Box)({

})


const Customtextinput: any = styled(TextField)({

  '& [class^="MuiInputBase-root-"]::before': {
    borderBottom: "none !important"
  },

  '& [class^="MuiInputBase-input-"]': {
    height: "30px !important"
  },
})

const Custompassinput: any = styled(TextField)({

  '& [class^="MuiInputBase-root-"]>input': {
    padding: "14.5px 14px !important",
  },

  '& fieldset': {
    borderStyle: "none !important",
  },



})


const webStyle = {
  profileImg: {
    width: "156px",
    height: "156px",
    // marginTop: 20,
    objectFit: "cover",
    border: "solid 4px #B2E4F8",
  },
  profileEditIcon: {
    position: "absolute",
    right: "50px",
    bottom: 0,
    cursor: 'pointer'
  },
  linkstyle: {
    fontSize: "16px",
    fontWeight: 700,
    textDecorationLine: "underline",
    marginRight: 50,
    textTransform: 'none',
    color: "#ED2258"
  },
  multiscreens: {
    display: "flex",
    padding: "20px"
  },
  logoutbtn: {
    width: "100%",
    height: "63px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4386A1",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none"
  },
  sidenav: {
    width: "325px",
    backgroundColor: "#B2E4F8",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  btngroup: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "10px"
  },
  btns: {
    width: "100%",
    height: "63px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "CCEDFB",
    color: "#000",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 400,
    '& .MuiButton-label': {
      marginLeft: 20
    }
  },
  innerContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "15px",
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    }

  },
  outerlayout: {
    padding: "0 30px",
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      padding: "0px"
    }
  },
  seetingBoContainer: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  settingsLayout: {
    marginTop: '30px'
  },
  labelsEmailPassword: {
    color: "#334155",
    fontWeight: 700,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  editbutton1: {
    width: "100%",
    height: "50%",
    display: "flex",
    borderRadius: "8px",
    color: "black"
  },

  editbutton2: {
    height: "50%",
    width: "100%",
    paddingLeft: "10%",
    display: "flex",
    color: "black",
    borderRadius: "8px",
  },
  inputEmailPassword: {
    width: "97%",
    height: "44px",
    borderRadius: "9px",
    fontSize: "14px",
    marginTop: "5px",
    fontWeight: "400",
    border: "1px solid #CCEDFB",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  visiblestyle: {
    color: "#94A3B8",
    cursor: "pointer"
  },
  inputbox: {
    border: "1px solid #CCEDFB",
    fontSize: "16px",
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    marginTop: "8px",
    marginBottom: "3px",
    paddingLeft: "16px"
  },
  lableText: {
    fontSize: "14px",
    fontFamily: "inter",
    color: "#000",
    fontWeight: 700,

  },
  mainbox: {
    minHeight: "600px",
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    // marginTop: "110px",
  },
  innerBox: {
    width: "100%",
    // height:"100vh",
    // backgroundColor:"yellow",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "5%"
  },
  maingrid: {
    display: "flex",
    borderBottomRightRadius: "35px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    height: "auto",
    minHeight: "150px",
    flexWrap: "wrap-reverse",
    marginBottom: "5%",
    fontFamily: "inter",
    backgroundImage: `url(${BOheaderbg})`,
    backgroundSize: "cover",
    backgroundColor: "#54A7C9"
  },
  firstgrid: {
    color: "#e8eaf6",
    display: "flex",
    justifyContent: "center",
    height: "120px",
    position: "relative",
  },
  salutationSelect: {
    width: "40%",
    paddingLeft: "10px",
    border: "1px solid #CBD5E2",
    height: "38px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },

  salutationSpan: {
    color: "#64748B",
    fontSize: "16px",
    fontWeight: 400, fontFamily: "inter", textTransform: "capitalize"
  },
  reservationContent: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  erroMessage: {
    margin: '5px 0px 10px',
    color: "red"
  },
  inputLabelText: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold

  }

};

const CssTextField = withStyles({
  root: {


    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "8px",
        borderColor: '#CBD5E1',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#54A7C9',
      },

      '&.Mui-focused fieldset': {
        borderWidth: "2px",
        borderRadius: "8px",
        borderColor: '#54A7C9',
      },
    },
  },
})(TextField);
// Customizable Area End
